import mixins from '#/app/styles';
import { BusinessUserList } from '#/components/BusinessUserList/BusinessUserList';
import Heading from '#/components/Heading/Heading';
import Loader from '#/components/Loader/Loader';
import Pagination from '#/components/Pagination/Pagination';
import Tooltip from '#/components/Tooltip';
import { useAdminUsersQuery } from '#/hooks/use-admin-users';
import { View, StyleSheet, Pressable, TextInput } from 'react-native';
import GridIcon from '#/assets/icons/grid.svg';
import DollarIcon from '#/assets/icons/currency-usd.svg';
import ViewListIcon from '#/assets/icons/view-list.svg';
import Button from '#/components/Button/Button';
import { BusinessUserGrid } from '#/components/BusinessUserGrid/BusinessUserGrid';
import { useAdminTariffsQuery } from '#/api/api.portal';
import { BusinessUserManageList } from '#/components/BusinessUserManageList/BusinessUserManageList';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { useSessionStorage } from 'usehooks-ts';
import useDebounce from '#/hooks/use-debounce';
import ChatsIcon from '#/assets/icons/message-processing-outline.svg';
import { BusinessUsersChats } from '#/components/BusinessUsersChats/BusinessUsersChats';
import React from 'react';
import { useChatMessagesStore } from '#/store/chatMessages/chatMessagesStore';

const styles = StyleSheet.create({
  heading: {
    marginBottom: 0,
  },
  headingNotFound: {
    marginTop: 100,
    marginHorizontal: 'auto',
  },
  addButton: {
    alignSelf: 'flex-start',
    marginTop: mixins.indent.i8,
  },
  actionRow: {
    flexDirection: 'row',
    marginBottom: mixins.indent.i5,
    alignItems: 'center',
  },
  wrapper: {
    maxWidth: 1300,
  },
  headerControls: {
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    gap: 10,
  },
  select: {
    paddingVertical: 5,
    marginLeft: mixins.indent.i10,
    width: 150,
    backgroundColor: mixins.color.dark,
    borderColor: mixins.color.dark,
  },
  activeView: {
    borderColor: mixins.color.blue,
  },
  listButton: {
    borderWidth: 2,
    borderColor: mixins.color.dark,
    borderStyle: 'solid',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    backgroundColor: mixins.color.darker,
    color: mixins.color.whiteText,
    paddingVertical: 9,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
});
type ListType = 'list' | 'grid' | 'manage-list' | 'stats' | 'chats';

export const HomeScreenAdmin = () => {
  const { clearChatsData } = useChatMessagesStore();
  const { selectUserAsAdmin, selectedUserId } = useSelectedBusinessUserStore();
  const [listType, setListType] = useSessionStorage<ListType>('al:listType', 'list');
  const [search, setSearch] = useSessionStorage('al:listSearch', '');
  const searchDebounced = useDebounce(search, 250);
  const {
    users,
    isLoading,
    totalPages,
    type,
    toggleType,
    page,
    setCurrentPage,
    setOnlineOnly,
    onlineOnly,
  } = useAdminUsersQuery({ perPage: listType === 'grid' ? 28 : 15, search: searchDebounced });
  const { data: tariffs } = useAdminTariffsQuery();

  const selectUser = (id: string | number | null) => {
    const user = users?.find(u => u.id === id);

    clearChatsData();
    selectUserAsAdmin(user.businessOwnerId, user.id, user.login);
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.actionRow}>
        <Heading style={styles.heading}>{searchDebounced ? 'Search results' : 'Users'}</Heading>
        {searchDebounced.length > 0 && (
          <Button secondary style={mixins.styles.margin.leftI10} onPress={() => setSearch('')}>
            Clear
          </Button>
        )}
        <View style={styles.headerControls}>
          <TextInput
            placeholder="Search users"
            style={styles.search}
            onChangeText={setSearch}
            value={search}
          />
          <Button onPress={() => setOnlineOnly(!onlineOnly)}>
            {onlineOnly ? 'Online only' : 'All users'}
          </Button>
          <Button onPress={toggleType}>
            {type === 'business' ? 'Business users' : 'Individual users'}
          </Button>
          <Tooltip text="List view">
            <Pressable
              style={[styles.listButton, listType === 'list' && styles.activeView]}
              onPress={() => setListType('list')}
            >
              <ViewListIcon height={25} fill={mixins.color.white} title="List view" />
            </Pressable>
          </Tooltip>
          <Tooltip text="Card view">
            <Pressable
              style={[styles.listButton, listType === 'grid' && styles.activeView]}
              onPress={() => setListType('grid')}
            >
              <GridIcon height={20} fill={mixins.color.white} />
            </Pressable>
          </Tooltip>
          <Tooltip text="Accounting">
            <Pressable
              style={[styles.listButton, listType === 'manage-list' && styles.activeView]}
              onPress={() => setListType('manage-list')}
            >
              <DollarIcon height={25} fill={mixins.color.white} />
            </Pressable>
          </Tooltip>
          <Tooltip text="Chats">
            <Pressable
              style={[styles.listButton, listType === 'chats' && styles.activeView]}
              onPress={() => setListType('chats')}
            >
              <ChatsIcon height={25} color={mixins.color.white} />
            </Pressable>
          </Tooltip>
        </View>
      </View>
      {isLoading ? (
        <Loader />
      ) : users && users.length > 0 ? (
        <>
          {listType === 'list' && (
            <BusinessUserList
              users={users}
              selectUser={selectUser}
              selectedUserId={selectedUserId}
            />
          )}
          {listType === 'grid' && (
            <BusinessUserGrid
              users={users}
              tariffs={tariffs || []}
              selectUser={selectUser}
              selectedUserId={selectedUserId}
            />
          )}
          {listType === 'manage-list' && (
            <BusinessUserManageList
              users={users}
              tariffs={tariffs || []}
              selectUser={selectUser}
              selectedUserId={selectedUserId}
            />
          )}
        </>
      ) : (
        <Heading style={styles.headingNotFound}>No users found</Heading>
      )}
      <BusinessUsersChats users={users || []} visible={listType === 'chats'} adminView />
      {listType !== 'chats' && (
        <View style={mixins.styles.margin.topI12}>
          <Pagination
            currentPage={page}
            handlePageChange={setCurrentPage}
            totalPages={totalPages}
            pagesToDisplay={10}
          />
        </View>
      )}
    </View>
  );
};
